export const getRemovecookieOption = () => {
    return { path: '/', domain: '.etutordev.com' };
};

export const getCookie = (cookieName) => {
    return document.cookie
        .split('; ')
        .find(row => row.startsWith(`${cookieName}=`))
        ?.split('=')[1];
};

export const getCookieOptions = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    const cookieOptions = {
        expires: expirationDate,
        domain: process.env.COOKIE_DOMAIN,
        // domain: '.7thtowertech.com',
        // domain: process.env.ENVIRONMENT_FLAG ? process.env.ETC_DEPLOYMENT_DOMAIN : process.env.ETC_DEPLOYMENT_DOMAIN,
        httpOnly: false,
    };
    return cookieOptions;
};
