import React from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export const RephraseDropdownButton = ({
  onModelClick,
  buttonText,
  modelAName,
  modelBName,
  loading,
  disabled,
  buttonStyle,
  buttonClassName,
  buttonSize,
  buttonShape,
}) => {
  const handleMenuClick = (e) => {
    onModelClick(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Rephrase">
        {modelAName}
      </Menu.Item>
      <Menu.Item key="Rephrase2">
        {modelBName}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button
        style={buttonStyle}
        className={buttonClassName}
        size={buttonSize}
        type="primary"
        shape={buttonShape}
        disabled={disabled}
        loading={loading}
      >
        {buttonText} <DownOutlined />
      </Button>
    </Dropdown>
  );
};