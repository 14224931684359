import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Layout,
  Typography,
  Input,
  Button,
  message,
  Card,
} from "antd";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  approveApprovalRequest,
  getApprovalRequestStatus,
  getLastWeekGenerations,
  getPastLoginApprovalRequestDetails,
  getPastLoginFingerprintDetails,
} from "./utils/apiRequester";
import AuditHistoryTable from "./AuditHistoryTable";
import { useParams } from "react-router-dom";
import withAuth from "./hooks/withAuth";
// import withAuth from '../hooks/withAuth';


function LoginApprovalActionPage() {
  const [approvalRequestId, setApprovalRequestId] = useState("");
  const [approvalRequestDetails, setApprovalRequestDetails] = useState(null);
  const [isRequestDetailsLoading, setIsRequestDetailsLoading] = useState(false);
  const [isApprovalRequestLoading, setIsApprovalRequestLoading] = useState(false);
  const [pastLoginFingerprintDetails, setPastLoginFingerprintDetails] = useState([]);
  const [lastWeekGenerateRequests, setLastWeekGenerateRequests] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);

  const params = useParams();

  async function loadUserAuditHIstory(email) {
    // const pastLoginFingerprintDetilsPromise = getPastLoginFingerprintDetails({ email });
    const pastLoginApprovalRequestPromise = getPastLoginApprovalRequestDetails({ email });
    const lastWeekGenerateRequestsPromise = getLastWeekGenerations({ email });
    const [pastLoginApprovalRequestDetails, lastWeekGenerateRequestsResult] = await Promise.allSettled([pastLoginApprovalRequestPromise, lastWeekGenerateRequestsPromise]);
    // const [pastLoginFingerprintDetailsResult, lastWeekGenerateRequestsResult] = await Promise.allSettled([pastLoginFingerprintDetilsPromise, lastWeekGenerateRequestsPromise]);
    
    let mappedLastWeekGenerateRequests = [], mappedPastLoginFingerprintDetails = [];

    // if (pastLoginFingerprintDetailsResult.status === "fulfilled" && pastLoginFingerprintDetailsResult?.value?.length) {
    //   mappedPastLoginFingerprintDetails = pastLoginFingerprintDetailsResult.value;
    //   setPastLoginFingerprintDetails(pastLoginFingerprintDetailsResult.value);
    // } else {
    //   setPastLoginFingerprintDetails([]);
    // }
    if (pastLoginApprovalRequestDetails.status === "fulfilled" && pastLoginApprovalRequestDetails?.value?.length) {
      mappedPastLoginFingerprintDetails = pastLoginApprovalRequestDetails.value.map(e=>({
        _id: e._id,
        timestamp: e.timestamp ? new Date(e.timestamp).getTime() : '',
        linkedId: e.email,
        browserDetails: {userAgentCustom: e.userAgent ?? 'DEFAULLT UA'},
        ip: e.ip ?? 'DEFAULT IP',
        ipInfo: null,
        tag: {textgenCustomPropertyLoginStatus: 'NEEDS_APPROVAL'},
        approvedBy: e.approvedBy,
        approvalTimestamp: e.approvalTimestamp,
        browserId: e.browserId ?? '',
      }));
      setPastLoginFingerprintDetails(pastLoginApprovalRequestDetails.value);
    } else {
      setPastLoginFingerprintDetails([]);
    }
  
    if (lastWeekGenerateRequestsResult.status === "fulfilled" && lastWeekGenerateRequestsResult?.value?.length) {
      mappedLastWeekGenerateRequests = lastWeekGenerateRequestsResult.value.map(e=>({
        _id: e._id,
        timestamp: e.time_stamp ? new Date(e.time_stamp).getTime() : '',
        linkedId: e.email,
        browserDetails: {userAgentCustom: e.user_agent ?? 'UNAVAILABLE'},
        ip: e.host_ip ?? 'UNAVAILABLE',
        ipInfo: null,
        tag: {textgenCustomPropertyLoginStatus: 'Generate Text', subject: e.subject ?? 'UNAVAILABLE'},
        approvedBy: '',
        approvalTimestamp: '',
        browserId: e.browserId ?? '',
      }))
      setLastWeekGenerateRequests(mappedLastWeekGenerateRequests);
    } else {
      setLastWeekGenerateRequests([]);
    }
    setSortedTableData([...mappedLastWeekGenerateRequests, ...mappedPastLoginFingerprintDetails].sort((a, b) => b.timestamp - a.timestamp));
  }

  useEffect(() => {
    if (approvalRequestDetails?.email) {
      loadUserAuditHIstory(approvalRequestDetails.email);
    }
  }, [approvalRequestDetails]);

  useEffect(() => {
    if (params.approvalId) {
      setApprovalRequestId(params.approvalId);
      setIsRequestDetailsLoading(true);
      getApprovalRequestStatus({ requestId: params.approvalId })
        .then((res) => {
          setApprovalRequestDetails(res);
          setIsRequestDetailsLoading(false);
        })
        .catch((err) => {
          message.error("Request ID is not valid");
          setIsRequestDetailsLoading(false);
          console.log(err);
        });
    }
  }, []);

  return (
    <>
      <div
        style={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <label for="approvalRequestId" style={{ fontWeight: "bold" }}>
          Approval Request ID
        </label>
        <Input
          placeholder="Approval Request ID"
          onChange={(e) => setApprovalRequestId(e.target.value)}
          style={{ width: "400px" }}
        />
        <Button
          loading={isRequestDetailsLoading || isApprovalRequestLoading}
          style={{ width: "200px" }}
          type="primary"
          onClick={() => {
            setIsRequestDetailsLoading(true);
            getApprovalRequestStatus({ requestId: approvalRequestId })
              .then((res) => {
                setApprovalRequestDetails(res);
                setIsRequestDetailsLoading(false);
              })
              .catch((err) => {
                message.error("Request ID is not valid");
                setIsRequestDetailsLoading(false);
                console.log(err);
              });
          }}
        >
          Get Details
        </Button>
      </div>
      {approvalRequestDetails && (
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Card
            title="Login request details"
            bodyStyle={{
              maxWidth: "600px",
              width: "fit-content",
            }}
          >
            <p>Email: {approvalRequestDetails?.email}</p>
            <p>Status: {approvalRequestDetails?.status}</p>
            {approvalRequestDetails?.browserId && (
              <p>Browser ID: {approvalRequestDetails?.browserId}</p>
            )}
            {approvalRequestDetails?.visitorDetails?.browserName && (
              <p>
                Browser Name:{" "}
                {approvalRequestDetails.visitorDetails.browserName}
              </p>
            )}
            {approvalRequestDetails?.visitorDetails?.browserVersion && (
              <p>
                Browser Version:{" "}
                {approvalRequestDetails.visitorDetails.browserVersion}
              </p>
            )}
            {approvalRequestDetails?.visitorDetails?.device && (
              <p>Device: {approvalRequestDetails.visitorDetails.device}</p>
            )}
            {approvalRequestDetails?.visitorDetails?.incognito && (
              <p>
                Incognito Mode:{" "}
                {approvalRequestDetails.visitorDetails.incognito ? "Yes" : "No"}
              </p>
            )}
            {approvalRequestDetails?.visitorDetails?.ip && (
              <p>IP Address: {approvalRequestDetails.visitorDetails.ip}</p>
            )}
            {approvalRequestDetails?.visitorDetails?.os &&
              approvalRequestDetails?.visitorDetails?.osVersion && (
                <p>
                  Operating System: {approvalRequestDetails.visitorDetails.os} (
                  {approvalRequestDetails.visitorDetails.osVersion})
                </p>
              )}
            {approvalRequestDetails?.visitorDetails?.visitorId && (
              <p>
                Visitor ID: {approvalRequestDetails.visitorDetails.visitorId}
              </p>
            )}
          </Card>
          <Button
            disabled={approvalRequestDetails?.status === "APPROVED"}
            loading={isRequestDetailsLoading || isApprovalRequestLoading}
            style={{ width: "200px" }}
            type="primary"
            onClick={() => {
              setIsApprovalRequestLoading(true);
              approveApprovalRequest({ requestId: approvalRequestId })
                .then((res) => {
                  if (res?.updateActiveSessionResult?.modifiedCount === 1) {
                    message.success("Request Approved");
                  }
                  setIsApprovalRequestLoading(false);
                  setIsRequestDetailsLoading(true);
                  getApprovalRequestStatus({ requestId: approvalRequestId })
                    .then((res) => {
                      setApprovalRequestDetails(res);
                      setIsRequestDetailsLoading(false);
                    })
                    .catch((err) => {
                      setIsRequestDetailsLoading(false);
                      console.log(err);
                    });
                })
                .catch((err) => {
                  message.error("Request Failed");
                  setIsApprovalRequestLoading(false);
                  console.log(err);
                });
            }}
          >
            Approve
          </Button>
          <div>
            <h2>User Audit History</h2>
            <div>
              <h3>Last Week Generations</h3>
              <div>
                <AuditHistoryTable data={sortedTableData} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    // inupt with button that takes requestid and sends it to backend, on success show the approval request details returned in response along with a button that says approve on click this button calls another endpoint to approve the request
  );
}

export default withAuth(LoginApprovalActionPage);
