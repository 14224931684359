import { useContext, useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import store from "store";
import { addFingerprintInfoForSuccessfulLogin, addFingerprintInfoToLoginApprovalRequests, apiRequester, getFreshToken } from "../utils/apiRequester";
import {
  getCookie,
  getCookieOptions,
  getRemovecookieOption,
} from "../utils/misc";
import jwtDecode from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import jwt from "jsonwebtoken";
// import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react'

export const useAuth = () => {
  const [authLoading, setAuthLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState();
  const router = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([
    "authToken",
    "user",
  ]);
  const location = useLocation();

  useEffect(() => {
    async function getAllUserDetails() {
      const authToken = getCookie("authToken");
      if (authToken) {
        const localUser = store.get("user");
        let userId = localUser?._id;
        if (!userId) {
          const decodedToken = jwt.decode(authToken, { complete: true });
          userId = decodedToken?.payload?.data.userId;
        }
        console.log("Local User is", localUser);
        const userResponse = await apiRequester.getUser(userId);
        store.set("user", userResponse);
        setLoggedInUser(userResponse);
      }
    }
    getAllUserDetails();
  }, []);

  // const {isLoading, error, data, getData} = useVisitorData(
  //   {
  //     extendedResult: true,
  //   },
  //   {immediate: true}
  // );

  const goToLoginPage = () => {
    const currentUrl = window.location.pathname;
    router(`/?redirectUrl=${encodeURIComponent(currentUrl)}`);
  };

  const adminCheck = () => {
    const user = store.get("user");
    if (!user?.roles?.includes("admin")) {
      router("/");
    }
  };

  const notGeneralUserCheck = () => {
    const user = store.get("user");
    if (!user?.roles || user.roles.includes("user")) {
      router("/");
    }
  };

  const isAdmin = store.get("user")?.roles?.includes("admin");

  const localLoginCheck = async () => {
    console.log("LocalLogin Check");
    const localToken = getCookie("authToken");
    let user = store.get("user");
    setLoggedInUser(user);
    if (!localToken || !user) {
      !localToken && console.log("Token not found.");
      !user && console.log("Current user details not found.");
      removeCookie("authToken", getRemovecookieOption());
      store.remove("user");
      goToLoginPage();
    }

    return true;
    // if (roles?.includes("admin")) router("/");
    // else router("/");
  };

  const login = async (emailId, password) => {
    try {
      setAuthLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      const loginResponse = await apiRequester.login({ emailId, password });
      const { token, user } = loginResponse;
      console.log("Login response is", loginResponse);
      setCookie("authToken", token, getCookieOptions());
      const decodedToken = jwtDecode(token);
      //@ts-ignore
      const { _id, roles } = loginResponse.user;
      console.log("User is", user);
      store.set("user", user);
      setLoggedInUser(user);
      // try {
      //   const visitorData = await getData({
      //     ignoreCache: true,
      //     linkedId: emailId ?? "defaultEmail@edubot.com",
      //     tag: {
      //       token,
      //       user,
      //       textgenCustomPropertyLoginStatus: "LOGGED_IN",
      //     }
      //   });
      //   if (visitorData?.requestId) {
      //     const fingerprintRequestId = visitorData?.requestId
      //     await addFingerprintInfoForSuccessfulLogin({ user, token, fingerprintRequestId, visitorDetails: visitorData });
      //   }
      // } catch (err) {
      //   console.log("Fingerprint not written fo successful login", err);
      //   // handleError(err);
      // }

      // Get the redirectUrl from the query parameters
      const searchParams = new URLSearchParams(location.search);
      const redirectUrl = searchParams.get("redirectUrl");
      if (redirectUrl) {
        router(redirectUrl);
      } else {
        // router("/");
      }
      // if (roles?.includes("admin")) router("/");
      // else router("/");
    } catch (err) {
      console.log("Error is", err);
      // handleError(err);
      // if (err?.code === "ALREADY_LOGGED_IN") {
      //   try {
      //     const approvalRequestId = err?.extra?.approvalRequest?.insertResult?.insertedId;
      //     const token = err?.extra?.approvalRequest?.token;
      //     const visitorData = await getData({
      //       ignoreCache: true,
      //       linkedId: emailId ?? "defaultEmail@edubot.com",
      //       tag: {
      //         token,
      //         textgenCustomPropertyLoginStatus: "NEEDS_APPROVAL"
      //       }
      //     });
      //     if (visitorData?.requestId) {
      //       const fingerprintRequestId = visitorData?.requestId
      //       await addFingerprintInfoToLoginApprovalRequests({ token, approvalRequestId, fingerprintRequestId, visitorDetails: visitorData });
      //     }
      //   } catch (err) {
      //     console.log("Fingerprint not written fo successful login", err);
      //     // handleError(err);
      //   }
      // }
      router(0);
    } finally {
      setAuthLoading(false);
    }
  };

  const logout = () => {
    removeCookie("authToken");
    store.remove("user");
    store.remove("approvalRequestapprovalRequest");
    store.remove("approvalRequestIdapprovalRequestId");
    store.remove("approvalRequestTrackingIdapprovalRequestTrackingId");
    goToLoginPage();
    setLoggedInUser(undefined);
  };

  return {
    authLoading,
    login,
    localLoginCheck,
    logout,
    loggedInUser,
    adminCheck,
    isAdmin,
    notGeneralUserCheck,
  };
};

export default useAuth;
