import React from 'react'
import { Table } from 'antd';
import moment from 'moment';
import UAParser from 'ua-parser-js';

const formatTimestamp = (timestamp) => {
  if (!timestamp) {
    return 'DEFAULT TIMESTAMP';
  }
  const date = new Date(timestamp);
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };
  return date.toLocaleString('en-US', options);
};

const columns = [
 {
   title: 'Timestamp',
   dataIndex: 'timestamp',
   key: 'timestamp',
   render: (timestamp) => <span>{formatTimestamp(timestamp)}</span>,
 },
 {
   title: 'Action',
   dataIndex: 'tag',
   key: 'tag',
   render: (tag) => tag?.textgenCustomPropertyLoginStatus ? tag.textgenCustomPropertyLoginStatus : 'GENERATE',
 },
 {
  title: 'Subject',
  dataIndex: 'tag',
  key: 'tag',
  render: (tag) => tag?.subject ? tag.subject : '',
},
 {
   title: 'Email',
   dataIndex: 'linkedId',
   key: 'linkedId',
 },
 {
   title: 'IP',
   dataIndex: 'ip',
   key: 'ip',
 },
//  {
//    title: 'Location',
//    dataIndex: 'ipInfo',
//    key: 'ipInfo',
//    render: (ipInfo) => {
//     if (!ipInfo) {
//       return '';
//     }
//     if (ipInfo?.v4?.geolocation?.city?.name && ipInfo?.v4?.geolocation?.country?.name) {
//       return `${ipInfo.v4.geolocation.city.name}, ${ipInfo.v4.geolocation.country.name}`;
//     }
//     if (ipInfo?.v4?.geolocation?.city?.name) {
//       return ipInfo.v4.geolocation.city.name;
//     }
//     if (ipInfo?.v4?.geolocation?.country?.name) {
//       return ipInfo.v4.geolocation.country.name;
//     }
//     return '';
//    },
//  },
 {
  title: 'Browser',
  dataIndex: 'browserDetails',
  key: 'browserDetails',
  render: (browserDetails) => {
    if (browserDetails?.userAgentCustom) {
      const parserResult = UAParser(browserDetails.userAgentCustom);
      console.log(parserResult);
      return `${parserResult.browser.name} ${parserResult.browser.version}`;
    } else {
      return `${browserDetails.browserName} ${browserDetails.browserFullVersion}`
    }
  }
},
{
  title: 'ApprovedBy',
  dataIndex: 'approvedBy',
  key: 'approvedBy',
  render: (approvedBy) =>
    approvedBy ? <span>{approvedBy}</span> : '',
},
{
  title: 'Approval Timestamp',
  dataIndex: 'approvalTimestamp',
  key: 'approvalTimestamp',
  render: (approvalTimestamp) =>
    approvalTimestamp ? <span>{formatTimestamp(approvalTimestamp)}</span> : '',
},
{
  title: 'Browser ID',
  dataIndex: 'browserId',
  key: 'browserId',
},
];

function AuditHistoryTable({ data }) {
  return (
    <Table columns={columns} dataSource={data} rowKey="_id" />
  )
}

export default AuditHistoryTable