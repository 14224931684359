import React, { Component, useEffect, useState } from "react";
import "./App.css";
import {
  Layout,
  Typography,
  Input,
  Slider,
  InputNumber,
  Row,
  Col,
  Button,
  message,
  AutoComplete,
  Select,
  Modal,
  Form,
  Tooltip,
  Space,
  Dropdown,
  Menu,
  MenuProps,
  Divider,
} from "antd";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import axios from "axios";
import { DownOutlined, UserOutlined, HistoryOutlined } from "@ant-design/icons";
import { LandingPageHero } from "./components/LandingPageHero";
import { ValueAddDescriptors } from "./components/ValueAddDescriptors";
import { LoginModal } from "./components/LoginModal";
import { FeatureList } from "./components/FeatureList";
import { GeneratedText } from "./components/GeneratedText";
import { VariationHistoryMenu } from "./components/VariationHistoryMenu";
import { GenerateTextDrawer } from "./components/GenerateTextDrawer";
import jwt from "jsonwebtoken";
import {
  getPromptSuggestionArray,
  allSubjects,
  recommendedSubjects,
} from "./promptSuggestionArray";
import { useCookies } from "react-cookie";
import { getCookieOptions, getCookie } from "./libs/utils/authHelpers";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { CopyOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useAuth from "./hooks/useAuth";
import withAuth from "./hooks/withAuth";
import { RephraseDropdownButton } from "./components/ModelSelectionDropdown";
import { generateTextProxy } from "./utils/apiRequester";


axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

const { Header, Content, Footer } = Layout;
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const FormData = require("form-data");
const { Option } = Select;
const { SubMenu } = Menu;

export const RephrasePage = withAuth(() => {
  const [originalText, setOriginalText] = useState("");
  const [rephrasedText, setRephrasedText] = useState("");
  const [isRephraseProcessing, setIsRephraseProcessing] = useState(false);
  const {loggedInUser}=useAuth()

  const generateRephrase = (eventButton = 'Rephrase2') => {
    setIsRephraseProcessing(true);
    // const eventButton1 = eventButton;
    // const url = `https://textgen-api.happyforest-b0203bd1.centralindia.azurecontainerapps.io/generate`;
    // const form = new FormData();
    // form.set("text", originalText);
    // form.set("buttonName", eventButton1);
    // form.set("email", loggedInUser.emailId);
    // form.set("displayName", `${loggedInUser.firstName} ${loggedInUser.lastName}`);
    // axios
    //   .post(url, form)
    //   .then((res) => {
    //     setIsRephraseProcessing(false);
    //     setRephrasedText(res.data.text);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setIsRephraseProcessing(false);
    //   });

      const eventButton1 = eventButton;
      //setReferences([]);
      //setGenerate(true);
      const text = originalText;
      const buttonName = eventButton1;
      const email = loggedInUser.emailId;
      const displayName = `${loggedInUser.firstName} ${loggedInUser.lastName}`;
      generateTextProxy({ text, buttonName, email, displayName }, 'https://textgen-api.happyforest-b0203bd1.centralindia.azurecontainerapps.io/generate')
      .then((res) => {
        setIsRephraseProcessing(false);
        setRephrasedText(res.text);
      })
      .catch((error) => {
        console.log(error);
        setIsRephraseProcessing(false);
      });
  };

  const handleCopy = () => {
    alert("Text copied to clipboard!");
  };

  if (true) {
    return (
      <>
        <div style={{ display: "flex", gap: "12px", padding: "20px" }}>
          <div>
            <div style={{ position: "relative" }}>
              <textarea
                name="original-text"
                id="original-text"
                className="thin-scrollbar"
                value={originalText}
                onInput={(e) => setOriginalText(e.target.value)}
                placeholder="Enter your text here"
                style={{ minHeight: "400px", width: "45vw", borderRadius: "4px", padding: "8px" }}
              ></textarea>
              <CopyToClipboard
                text={originalText}
                onCopy={handleCopy}
                style={{
                  right: "0",
                  bottom: "8px",
                  position: "absolute",
                  margin: "8px",
                }}
              >
                <CopyOutlined />
              </CopyToClipboard>
            </div>
            {/* <div>
              <Button 
                type="primary"
                onClick={generateRephrase}
                loading={isRephraseProcessing}
              >
                Rephrase
              </Button>
            </div> */}
            <div>
              <RephraseDropdownButton
                onModelClick={generateRephrase}
                buttonText="Rephrase"
                modelAName="Model A"
                modelBName="Model B"
                loading={isRephraseProcessing}
                disabled={isRephraseProcessing}
                buttonStyle={{
                  marginRight: "20px",
                  background: "#4FA8DC",
                  borderRadius: "10px",
                  border: "none",
                }}
                buttonClassName="button-dropdown-generate"
                buttonSize="large"
                buttonShape="round"
              />
            </div>
          </div>
          <div>
            <div style={{ position: "relative" }}>
              <textarea
                name="original-text"
                id="original-text"
                className="thin-scrollbar"
                value={rephrasedText}
                onInput={(e) => setRephrasedText(e.target.value)}
                style={{ minHeight: "400px", width: "45vw", borderRadius: "4px", padding: "8px" }}
              ></textarea>
              <CopyToClipboard
                text={rephrasedText}
                onCopy={handleCopy}
                style={{
                  right: "0",
                  bottom: "8px",
                  position: "absolute",
                  margin: "8px",
                }}
              >
                <CopyOutlined />
              </CopyToClipboard>
            </div>

          </div>
        </div>
      </>
    );
  }
});
