import React from "react";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useCookies } from "react-cookie";
import { Toaster, toast } from "react-hot-toast";
import withAuth from "../hooks/withAuth";
import { useNavigate } from "react-router-dom";
import { apiRequester } from "../utils/apiRequester";
import { Button, Input } from "antd";
import store from "store";
import userUser from "../hooks/useUser";
function Admin() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState(null);
  const [parent, setParent] = useState(null);
  const [validParents, setValidParents] = useState([]);
  const [showParentDropdown, setShowParentDropdown] = useState(false);
  const { users, refreshUsers, userLoading } = userUser();
  const router = useNavigate();
  const [cookies] = useCookies(["authToken"]);

  useEffect(() => {
    refreshUsers("",1000,0);
  }, []);

  function resetForm() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setRoles([]);
  }
  async function createUser(event) {
    event.preventDefault();
    if (firstName && lastName && email && password && roles) {
      const allRoles = roles.value
      try {
        await apiRequester.createUser({
          firstName,
          lastName,
          emailId: email,
          password,
          roles: [allRoles],
          parent: allRoles == 'admin' ? 'root' : parent.value
        });
        resetForm();
        toast.success("User created successfully!");
        refreshUsers("",1000,0);
      } catch (err) {
        console.log(err);
        toast.error("Error: Could not create user");
      }
    } else {
      toast.error("All fields are required");
    }
  }

  const localUser = store.get("user");
  const localUserRole = localUser ? localUser.roles[0] : "user";
  const allOptions = [
    { value: "admin", label: "Admin" },
    { value: "team_lead", label: "Team Lead" },
    { value: "handler", label: "Handler" },
    { value: "user", label: "User" },
  ];
  const options = localUserRole === "admin" ? allOptions : allOptions.slice(allOptions.findIndex(opt => opt.value === localUserRole) + 1);

  function resetParent() {
    setParent(null);
  }

  function getValidParents(selectedRole) {
    if (!selectedRole || selectedRole.value == 'admin') return [];
    else if (selectedRole.value == 'team_lead') return mapToUserOptions(users.filter(user => user.roles.includes('admin')));
    else if (selectedRole.value == 'handler') return mapToUserOptions(users.filter(user => user.roles.includes('team_lead')));
    else if (selectedRole.value == 'user') return mapToUserOptions(users.filter(user => user.roles.includes('handler')));
  }

  function mapToUserOptions(userOptions) {
    return userOptions?.map(user => ({
      label: `${user.firstName} ${user.lastName} (${user.emailId})`,
      value: user._id,
    })) ?? [];
  }

  const handleRoleChange = (selectedOptions) => {
    console.log(selectedOptions);
    setRoles(selectedOptions || []);
    resetParent();
    if (selectedOptions?.value == 'admin') {
      setParent("root");
      setShowParentDropdown(false);
    } else {
      setShowParentDropdown(selectedOptions ? true : false);
      setValidParents(getValidParents(selectedOptions));
    }
  };
  const handleParentChange = (selectedOptions) => {
    console.log(selectedOptions);
    setParent(selectedOptions || []);
  };
  return (
    <div className="h-screen">
      <div
        className=" flex h-[94vh] items-center bg-[#434654]"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Toaster position="bottom-center" reverseOrder={false} />
        <form className="m-auto flex w-1/3 flex-col items-center justify-center rounded-xl bg-white bg-opacity-5 py-12 shadow-xl shadow-gray-800">
          <h2 className="text-3xl font-bold text-white">Create User</h2>
          <Input
            required
            type="text"
            id="firstname"
            placeholder="FirstName"
            value={firstName}
            style={{ marginTop: 20 }}
            className="mt-6 h-8 w-5/6 rounded border py-5 pl-2"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
            required
            type="text"
            id="lastname"
            placeholder="LastName"
            value={lastName}
            style={{ marginTop: 20 }}
            className="mt-6 h-8 w-5/6 rounded border py-5 pl-2"
            onChange={(e) => setLastName(e.target.value)}
          />
          <Input
            required
            type="email"
            id="email"
            placeholder="Email"
            value={email}
            style={{ marginTop: 20 }}
            className="mt-6 h-8 w-5/6 rounded border py-5 pl-2"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            required
            type="password"
            id="password"
            placeholder="Password"
            value={password}
            style={{ marginTop: 20 }}
            className="mt-6 h-8 w-5/6 rounded border py-5 pl-2"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ marginTop: 20 }}>
            <Select
              closeMenuOnSelect={true}
              value={roles}
              name="roles"
              style={{ marginTop: 20 }}
              options={options}
              className="basic-multi-select mt-1 w-5/6 rounded py-5"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  "&:hover": {},
                  border: "1px solid #E6E7E9",
                  padding: "0.2rem",
                }),
              }}
              placeholder="Roles"
              onChange={handleRoleChange}
            />
          </div>
          {showParentDropdown && <div style={{ marginTop: 20 }}>
            <Select
              loading={userLoading}
              closeMenuOnSelect={true}
              value={parent}
              name="parent"
              style={{ marginTop: 20 }}
              options={validParents}
              className="basic-multi-select mt-1 w-5/6 rounded py-5"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  "&:hover": {},
                  border: "1px solid #E6E7E9",
                  padding: "0.2rem",
                }),
              }}
              placeholder="Parent"
              onChange={handleParentChange}
            />
          </div>}
          <Button
          type="primary"
            style={{ marginTop: 20 }}
            className="mt-2 w-5/6 rounded bg-[#202123] px-4 py-2 font-bold text-white"
            onClick={createUser}
          >
            Create
          </Button>
        </form>
      </div>
    </div>
  );
}

export default withAuth(Admin);

