import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Layout,
  Typography,
  Input,
  Button,
  Select,
  Form,
  Menu,
  Dropdown,
  Space,
} from "antd";
import store from "store";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import { getCookieOptions } from "./libs/utils/authHelpers";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import {
  Outlet,
  Link,
} from "react-router-dom";
import useAuth from "./hooks/useAuth";
import ApprovalPendingPage from "./ApprovalPendingPage";
import { getApprovalRequestStatus } from "./utils/apiRequester";
import { getRemovecookieOption } from "./utils/misc";
import { useNavigate } from "react-router-dom";
import { DownOutlined, LogoutOutlined, HistoryOutlined, UserOutlined } from "@ant-design/icons";
// Initialize Firebase

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { SubMenu } = Menu;


export const Parent = () => {
  const [firebaseToken, setFirebaseToken] = useState("");
  const [email, setEmail] = useState("");
  const { login, loggedInUser, localLoginCheck, logout } = useAuth();
  const [password, setPassword] = useState("");
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState("optional");
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isApprovalPending, setIsApprovalPending] = useState(false);
  const [approvalRequestId, setApprovalRequestId] = useState('ApprovalRequestId');

  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);

  const router = useNavigate();
  async function getApprovalRequest() {
    function removeApprovalRequestFromStorage() {
      localStorage.removeItem("approvalRequest");
      localStorage.removeItem("approvalRequestId");
      localStorage.removeItem("approvalRequestTrackingId");
      // window.location.reload();
    }
    function removeAuthToken() {
      removeCookie("authToken", getRemovecookieOption());
    }
    // removeApprovalRequestFromStorage();
    if (cookies["authToken"] && store.get("approvalRequest")) {
      setIsApprovalPending(true);
      setApprovalRequestId(store.get("approvalRequest")?.insertResult?.insertedId);
    }
    const approvalRequestIdLocal = store.get("approvalRequestId");
    if (!approvalRequestIdLocal) {
      removeApprovalRequestFromStorage();
      return;
    }
    const approvalRequestRes = await getApprovalRequestStatus({ requestId: approvalRequestIdLocal });
    if (!approvalRequestRes) {
      removeApprovalRequestFromStorage();
      removeAuthToken();
      return;
    }
    if (approvalRequestRes.status === "APPROVED") {
      removeApprovalRequestFromStorage();
      router(0);
      return;
    }
    if (approvalRequestRes.status === "PENDING") {
      const approvalRequestIdFresh = approvalRequestRes._id;
      const approvalRequestTrackingId = approvalRequestRes.trackingId
      const authToken = approvalRequestRes.token;
      setCookie("authToken", authToken, getCookieOptions());
      localStorage.setItem("approvalRequest", JSON.stringify(approvalRequestRes));
      localStorage.setItem("approvalRequestId", approvalRequestIdFresh);
      localStorage.setItem("approvalRequestTrackingId", approvalRequestTrackingId);
      setIsApprovalPending(true);
      setApprovalRequestId(approvalRequestIdFresh);
      return;
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("browserId")) {
      const id = Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
      localStorage.setItem("browserId", id);
    }
    if (cookies["authToken"]) {
      getApprovalRequest();
      localLoginCheck();
    }
  }, []);

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  console.log("Logged In User", loggedInUser);

  const signInUser = async () => {
    if (email && password) {
      setIsLoginLoading(true);
      try {
        const loginRes = await login(email, password);
        console.log(loginRes);
      } catch (error) {
      } finally {
        setIsLoginLoading(false);
      }
    }
  };

  if (isApprovalPending) {
    return (<ApprovalPendingPage
      approvalRequestId={approvalRequestId}
      setApprovalRequestId={setApprovalRequestId}
    />)
  }

  if (!loggedInUser) {
    return (
      <div className="App2">
        <Layout className="layout">
          <Header className="content">
            <Text
              style={{
                color: "#00FFFF",
                textAlign: "center",
                display: "block",
                margin: "0 auto",
                width: "100%",
              }}
            >
              Cherish Technologies INC
            </Text>
          </Header>
          <Content
            style={{
              minHeight: "100vh",
              paddingTop: "10px",
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Title>Login</Title>
            <Form
              form={form}
              style={{ width: "25%" }}
              layout="vertical"
              initialValues={{ requiredMarkValue: requiredMark }}
              onValuesChange={onRequiredTypeChange}
              requiredMark={requiredMark}
            >
              <Form.Item
                label="Email"
                required
                tooltip="This is a required field"
              >
                <Input
                  value={email}
                  size="large"
                  style={{ borderRadius: 0 }}
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                required
                tooltip="This is a required field"
              >
                <Input
                  value={password}
                  size="large"
                  type="password"
                  style={{ borderRadius: 0 }}
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item style={{ width: "100%" }}>
                <Button
                  style={{ width: "100%" }}
                  size="large"
                  type="primary"
                  onClick={signInUser}
                  loading={isLoginLoading}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Content>
        </Layout>
      </div>
    );
  }

  console.log("Logged iN user is", loggedInUser);


  return (
    <div className="App">
      <Layout className="layout">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
          }}
        >
          <div>
            <Header className="content">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Text style={{ color: "#00FFFF" }}>
                  Cherish Technologies INC
                </Text>
                <div>
                  {loggedInUser.roles[0] !== "user" && (
                    <>
                      <Link
                        to={`/admin`}
                        style={{ padding: "12px", color: "rgb(0, 255, 255)" }}
                      >
                        Create User
                      </Link>
                      <Link
                        to={`/users`}
                        style={{ padding: "12px", color: "rgb(0, 255, 255)" }}
                      >
                        List Users
                      </Link>

                      <Link
                        to={`/report`}
                        style={{ padding: "12px", color: "rgb(0, 255, 255)" }}
                      >
                        Generate Report
                      </Link>
                      {loggedInUser?.roles?.includes("admin") && <Link
                        to={`/approvals`}
                        style={{ padding: "12px", color: "rgb(0, 255, 255)" }}
                      >
                        Approvals
                      </Link>}
                    </>
                  )}
                  <Link
                    to={`/`}
                    style={{ padding: "12px", color: "rgb(0, 255, 255)" }}
                  >
                    Generate Text
                  </Link>
                  <Link
                    target="blank"
                    to={`/rephrase`}
                    style={{ padding: "12px", color: "rgb(0, 255, 255)" }}
                  >
                    Rephrase
                  </Link>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            <div>
                              <UserOutlined style={{ color: "black", marginRight: "8px" }} />
                              <span>{loggedInUser.firstName} {loggedInUser.lastName}</span>
                            </div>
                          ),
                          key: "0",
                          disabled: true,
                        },
                        ...(loggedInUser?.roles?.some((role) =>
                          ["handler", "admin", "team_lead"].includes(role)
                        )
                          ? [
                              {
                                label: <div onClick={() => logout()}>
                                  <LogoutOutlined style={{ color: "black", marginRight: "8px" }} />
                                  <span>Logout</span>
                                  </div>,
                                key: "1",
                              },
                            ]
                          : []),
                      ],
                    }}
                    trigger={["click"]}
                  >
                    <Space>
                      <UserOutlined style={{ color: "white", cursor: "pointer", margin:"0px 12px" }} />
                      {/* <LogoutOutlined style={{ color: "white" }} /> */}
                    </Space>
                  </Dropdown>
                  {/* {loggedInUser?.roles?.some(role => ["handler", "admin", "team_lead"].includes(role)) && (<Link
                    // to={`/report`}
                    style={{ padding: "12px", color: "rgb(0, 255, 255)" }}
                  >
                    <LogoutOutlined onClick={()=>{ logout() }} style={{ color: "white" }} />
                  </Link>)} */}
                  {/* <Space>
                  </Space> */}
                </div>
              </div>
            </Header>
            <Outlet
              context={[
                loggedInUser,
                `${loggedInUser.firstName} ${loggedInUser.lastName}`,
              ]}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};
