import { useState } from 'react';
import store from 'store';
import { apiRequester } from '../utils/apiRequester';


export const useUser = () => {
    const [userLoading, setUserLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [userInfoAll,setUserinfoAll]=useState()

    const refreshUsers = async (text="",limit=100,skip=0) => {
        try {
            setUserLoading(true);
            const users = await apiRequester.listUsers(text,limit,skip);
            setUserinfoAll(users);
            setUsers(users.users);
        } catch (err) {
            console.log("Error is",err)
        } finally {
            setUserLoading(false);
        }
    };


    const deleteUser = async (userId) => {

        try {
            setUserLoading(true);
            await apiRequester.deleteUser(userId);
            await refreshUsers();
        } catch (err) {
            console.log("Error is",err)
        } finally {
            setUserLoading(false);
        }
    };

    const reassignUser = async (userId, parentId) => {

        try {
            setUserLoading(true);
            await apiRequester.reassignUser(userId, parentId);
            await refreshUsers();
        } catch (err) {
            console.log("Error is",err)
        } finally {
            setUserLoading(false);
        }
    };

    return { userLoading, refreshUsers, users,userInfoAll,deleteUser, reassignUser};
};

export default useUser;
