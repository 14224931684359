import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Layout,
  Input,
  Button,
  Form,
  Tooltip,
  Space,
  Dropdown,
  Menu,
} from "antd";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import axios from "axios";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { LandingPageHero } from "./components/LandingPageHero";
import { ValueAddDescriptors } from "./components/ValueAddDescriptors";
import { LoginModal } from "./components/LoginModal";
import { FeatureList } from "./components/FeatureList";
import { GeneratedText } from "./components/GeneratedText";
import { VariationHistoryMenu } from "./components/VariationHistoryMenu";
import {
  allSubjects,
  recommendedSubjects,
} from "./promptSuggestionArray";
import { useCookies } from "react-cookie";
import useAuth from "./hooks/useAuth";
import { generateTextProxy } from "./utils/apiRequester";
import { RephraseDropdownButton } from "./components/ModelSelectionDropdown";


const { Content } = Layout;


export const App = () => {
  const {loggedInUser}=useAuth();

  const recommendations = recommendedSubjects.map((recommend, index) => {
    return {
      label: recommend,
      key: `${index + 1}`,
      icon: <UserOutlined />,
    };
  });

  const subjectList = allSubjects.map((recommend, index) => {
    return {
      label: recommend,
      key: `${recommendations.length + 3 + index}`,
      icon: <UserOutlined />,
    };
  });

  const filetrDropdownValues = [
    {
      label: "Recommended Subjects",
      disabled: true,
      icon: null,
    },
    ...recommendations,

    { type: "divider" },
    {
      key: `${recommendations.length + 1}`,
      label: "All Subjects",
      disabled: true,
    },
    ...subjectList,
  ];

  const [generate, setGenerate] = useState(false);
  const [reGenerate, setReGenerate] = useState(false);
  const [gettingVariations, setGettingVariations] = useState(false);
  const [gettingSuggestions, setGettingSuggestions] = useState(false);
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [userId, setUserId] = useState("");

  const [token, setToken] = useState("");
  const [query, setQuery] = useState("");
  const [buttonName, setButtonName] = useState(""); // generate button (subject)
  const [keyword, setKeyword] = useState("");

  const [lastUsedQuery, setLastUsedQuery] = useState("");
  const [lastUsedButtonName, setLastUsedButtonName] = useState(""); // generate button (subject)
  const [lastUsedKeyword, setLastUsedKeyword] = useState("");

  const [generatedText, setGeneratedText] = useState("");
  const [querySuggestions, setQuerySuggestions] = useState(null);
  const [references, setReferences] = useState([]);

  const [serpSearchResponse, setSerpSearchResponse] = useState(null);
  const [currentCaretPosition, setCurrentCaretPosition] = useState(0);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [loginPromptReason, setLoginPromptReason] = useState(
    "Login to continue using etutorconnect"
  );
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [saveSessionLoading, setSaveSessionLoading] = useState(false);
  const [autoApaButtonLoading, setAutoApaButtonLoading] = useState(false);
  const [plagiarismButtonLoading, setPlagiarismButtonLoading] = useState(false);
  const [refrencesLoading, setRefrencesLoading] = useState(false);
  //Add different models here
  const [filteredNames, setFilteredNames] = useState(filetrDropdownValues);
  const [variationHistory, setVariationHistory] = useState(
    JSON.parse(localStorage.getItem("variationHistory")) ?? []
  );

  useEffect(() => {
    setPageLoading(false);
  }, []);

  const onChange = ({ target: { value } }) => {
    setQuery(value);
  };

  const generateText = (fromInputs) => {
    try {
      getPromptSuggestions();
    } catch (e) {}
    const eventButton = fromInputs?.eventButton;
    setReferences([]);

    setGenerate(true);
    setLastUsedQuery(query);
    setLastUsedButtonName(eventButton ?? buttonName);
    setLastUsedKeyword(keyword);

    const text = query;
    const buttonName = eventButton ?? buttonName;
    const email = loggedInUser.emailId;
    const displayName = `${loggedInUser.firstName} ${loggedInUser.lastName}`;
    generateTextProxy({ text, buttonName, email, displayName })
      .then((res) => {
        // setResult(res.data.text);
        setGenerate(false);
        setGeneratedText(res.text);
        setIsLandingPage(false);
      })
      .catch((error) => {
        console.log(error);
        setGenerate(false);
      });
  };

  const generateRephrase = (eventButton = 'Rephrase') => {
    const eventButton1 = eventButton;
    setReferences([]);
    setGenerate(true);
    const text = generatedText;
    const buttonName = eventButton1;
    const email = loggedInUser.emailId;
    const displayName = `${loggedInUser.firstName} ${loggedInUser.lastName}`;
    generateTextProxy({ text, buttonName, email, displayName }, 'https://textgen-api.happyforest-b0203bd1.centralindia.azurecontainerapps.io/generate')
      .then((res) => {
        // setResult(res.data.text);
        setGenerate(false);
        setGeneratedText(res.text);
        setIsLandingPage(false);
      })
      .catch((error) => {
        console.log(error);
        setGenerate(false);
      });
  };

  const getReferences = (setAreReferencesLoaded, referencesKeyword) => {
    const params = new URLSearchParams(
      JSON.parse(
        JSON.stringify({
          q: referencesKeyword,
          start: 0,
          since: 2019,
        })
      )
    ).toString();
    axios
      .get(
        `https://auto-apa-backend-test-1-a3v4rktvya-uc.a.run.app/utility/google-scholar-search?${params}`
      )
      .then((res) => {
        setRefrencesLoading(false);
        setAreReferencesLoaded(true);
        setSerpSearchResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
        setAreReferencesLoaded(false);
        setRefrencesLoading(false);
      });
  };

  const regenerateText = () => {
    try {
      getPromptSuggestions();
    } catch (e) {}
    setReGenerate(true);
    const text = generatedText;
    const buttonName = lastUsedButtonName;
    const email = loggedInUser.emailId;
    const displayName = `${loggedInUser.firstName} ${loggedInUser.lastName}`;

    generateTextProxy({ text, buttonName, email, displayName })
      .then((res) => {
        // setResult(res.data.text);
        setReGenerate(false);
        setGeneratedText(res.text);
      })
      .catch((error) => {
        console.log(error);
        setReGenerate(false);
      });
  };

  const getPromptSuggestions = () => {
    if (!(query || keyword)) return;
    setGettingSuggestions(true);
    fetch("https://promptsugg.happyforest-b0203bd1.centralindia.azurecontainerapps.io/generate_suggestions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: query || keyword }),
    })
      .then((response) => response.json())
      .then((data) => {
        const variation = data.response;
        if (variation || variation.length) setQuerySuggestions(variation);
        setGettingSuggestions(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setGettingSuggestions(false);
      });
  };

  const fetchAndSetReferencesKeyword = (setKeywordFieldDisabled, setReferencesKeyword) => {
    // show error if !generated text?
    if (!(generatedText)) return;
    setKeywordFieldDisabled(true);
    fetch("https://promptsugg.happyforest-b0203bd1.centralindia.azurecontainerapps.io/generate_references", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: generatedText }),
    })
      .then((response) => response.json())
      .then((data) => {
        const referencesKeyword = data.response;
        setReferencesKeyword(referencesKeyword);
        setKeywordFieldDisabled(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setKeywordFieldDisabled(false);
      });
  };

  const fetchAndSetReferences = (setAreReferencesLoaded, referencesKeyword) => {
    try {
      if (!(referencesKeyword || keyword)) return;
      setRefrencesLoading(true);
      if (referencesKeyword) {
        getReferences(setAreReferencesLoaded, referencesKeyword);
      } else {
        const referencesKeyword = keyword;
        getReferences(setAreReferencesLoaded, referencesKeyword);
      }
    } catch(error) {
      console.error("There was an error!", error);
      setRefrencesLoading(false);
    };
  };

  const generateReferences = (setAreReferencesLoaded) => {
    setRefrencesLoading(true);
    if (!(generatedText || setAreReferencesLoaded)) return;
    fetch("https://promptsugg.happyforest-b0203bd1.centralindia.azurecontainerapps.io/generate_references", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: generatedText }),
    })
      .then((response) => response.json())
      .then((data) => {
        const referencesKeyword = data.response;

        if (referencesKeyword) {
          getReferences(setAreReferencesLoaded, referencesKeyword);
        } else {
          const referencesKeyword = keyword;
          getReferences(setAreReferencesLoaded, referencesKeyword);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setRefrencesLoading(false);
      });
  };

  const getPromptVariations = () => {
    if (!query) return;
    setGettingVariations(true);
    fetch("https://promptsugg.happyforest-b0203bd1.centralindia.azurecontainerapps.io/generate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: query }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Set response to localStorage
        const promptKey = query;
        const variation = data.response;

        if (!variation || !variation.length) {
          setGettingVariations(false);
          return;
        }

        let variationHistoryCopy = JSON.parse(
          localStorage.getItem("variationHistory")
        );
        if (!variationHistoryCopy) {
          variationHistoryCopy = [];
        }
        const index = variationHistoryCopy.findIndex((obj) => promptKey in obj);
        if (index !== -1) {
          variationHistoryCopy[index] = {
            [promptKey]: variation,
          };
        } else {
          variationHistoryCopy.unshift({
            [promptKey]: variation,
          });
        }
        localStorage.setItem(
          "variationHistory",
          JSON.stringify(variationHistoryCopy)
        );
        // Set response to state
        setVariationHistory(variationHistoryCopy);
        const element = document.querySelector("#prompt-history");
        if (element) element.click();
        setGettingVariations(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setGettingVariations(false);
      });
  };

  const handleMenuClick = (button) => {
    setButtonName(button);
    generateText({ eventButton: button });
  };

  const menu = (
    <Menu
      style={{ maxHeight: "50vh" }}
      onClick={async (button) => {
        const selectedItem = filteredNames[button.key];
        if (
          !selectedItem ||
          selectedItem?.disabled ||
          selectedItem?.type === "divider" ||
          !selectedItem?.label
        )
          return;
        setButtonName(selectedItem?.label);
        handleMenuClick(selectedItem?.label);
      }}
    >
      {filteredNames.map((model, index) =>
        model.type === "divider" ? (
          <Menu.Divider key={index}></Menu.Divider>
        ) : (
          <Menu.Item key={index} icon={model.icon} disabled={model.disabled}>
            {model.label}
          </Menu.Item>
        )
      )}
    </Menu>
  );

  const searchChange = ({ target: { value } }) => {
    setKeyword(value);
    // const filteredNames = modelNames.filter((modelName) => {
    //   if (modelName.toLowerCase().search(value.toLowerCase()) >= 0) return true;
    //   else return false;
    // });
    // if (!filteredNames.length) {
    //   setFilteredNames(modelNames)
    // } else {
    //   setFilteredNames(filteredNames)
    // }
  };

  if (pageLoading)
    return (
      <div
        style={{
          zIndex: "10",
          marginBottom: "-120px",
          textAlign: "center",
          position: "relative",
          padding: "40px",
        }}
      >
        <Loader type="TailSpin" color="#4FA8DC" height={100} width={100} />{" "}
        {/* <span>Loading...</span> */}
      </div>
    );

  return (
    <>
      {/* <Header className="content">
  </Header> */}
      {isLandingPage && <LandingPageHero />}
      {/* <div className="site-input-group-wrapper">
        <div className="fields-container">
          <Input
            style={{ width: "30%", color: "black" }}
            placeholder="Enter Title, Keyword... (Ex: Big Data)"
            onChange={searchChange}
          />
          <Input
            style={{ width: "70%", color: "black" }}
            placeholder="Write something here related to the topic (Ex: Uses of Big Data)"
            disabled={generate || !keyword}
            value={query}
            onChange={onChange}
            onFocus={() => setQuerySuggestions(keyword ? mockSuggestions : null)}
            onBlur={() => setQuerySuggestions(keyword ? mockSuggestions : null)}
          />
        </div>

        <Dropdown.Button
          overlay={menu}
          overlayStyle={{ maxHeight: "80vh", overflowY: "scroll" }}
          disabled={generate || !keyword || !query}
          loading={generate}
          icon={<DownOutlined />}
          type="primary"
          htmlType="submit"
          style={{
            marginRight: "20px",
            background: "#4FA8DC",
            borderRadius: "10px",
          }}
        >
          { buttonName || 'Generate'}
        </Dropdown.Button>
      </div> */}
      {/* <div className="suggestions-area">
        <div className="fields-suggestion-container">
          <div
            style={{ width: "28%", color: "rgba(0, 0, 0, 0.5)" }}
            // placeholder="Enter Title, Keyword..."
            // onChange={searchChange}
          >
            {querySuggestions ? "" : "Ex: Big Data"}
          </div>
          <div
            className="main-query-suggestion-list"
            style={{ width: "72%", color: "rgba(0, 0, 0, 0.5)" }}
            // placeholder="Write something here related to the topic"
            // disabled={generate}
            // value={query}
            // onChange={onChange}
          >
            {querySuggestions
              ? mockSuggestions.map((suggestion, ind) => (
                  <span
                    className="query-suggestion-item"
                    key={ind}
                    onClick={(e) => setQuery(e.target.textContent)}
                  >
                    {suggestion}
                  </span>
                ))
              : "Ex: Uses of Big Data"}
          </div>
        </div>
      </div> */}

      <div
        style={{
          display: "flex",
          width: "calc(100vw - 112px)",
          margin: "56px 56px 8px 56px",
          alignItems: "flex-start",
        }}
      >
        <div style={{ marginRight: "16px" }}>
          {/* <Input
                value={keyword}
                placeholder="Enter Title, Keyword... (Ex: Big Data)"
                onChange={searchChange}
              /> */}
          <Input
            style={{
              padding: "8px 12px",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              color: "#263238",
            }}
            value={keyword}
            placeholder="Enter Title, Keyword... (Ex: Big Data)"
            onChange={searchChange}
            onBlur={getPromptSuggestions}
          />
        </div>
        <div
          style={{
            marginRight: "16px",
            paddingTop: "8px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Space wrap style={{ alignItems: "flex-start" }}>
            {/* <Dropdown 
              className="prompt-history"
              trigger={['click']}
              overlay={(
                <Menu>
                  <SubMenu title="sub menu">
                    <Menu.Item>3rd menu item3rd menu item3rd menu item3rd menu item3rd menu item</Menu.Item>
                    <Menu.Item>4th menu item</Menu.Item>
                  </SubMenu>
                </Menu>
              )}>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <HistoryOutlined style={{ fontSize: '24px', color: '#4FA8DC' }} />
                  </a>
                </Dropdown> */}
            <VariationHistoryMenu
              variationHistory={variationHistory}
              setQuery={setQuery}
            />
          </Space>
        </div>
        <div style={{ marginRight: "16px", flex: "1" }}>
          <div>
            <Tooltip
              placement="top"
              title={keyword ? undefined : "Enter keyword first"}
            >
              <div>
                <Input.TextArea
                  placeholder="Write something here related to the topic (Ex: Uses of Big Data)"
                  disabled={generate || !keyword}
                  value={query}
                  onChange={onChange}
                  // onFocus={() => setQuerySuggestions(keyword ? getPromptSuggestionArray(keyword).sort(() => 0.5 - Math.random()).slice(0, 6) : null)}
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  style={{
                    padding: "8px 12px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    color: "#263238",
                  }}
                />
              </div>
            </Tooltip>
            {gettingSuggestions ? (
              <div
                style={{
                  zIndex: "10",
                  margin: "10px",
                  position: "relative",
                }}
              >
                <Loader
                  type="TailSpin"
                  color="#4FA8DC"
                  height={24}
                  width={24}
                />{" "}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "10px",
                }}
              >
                {querySuggestions?.map((suggestion, index) => {
                  return (
                    <span
                      style={{
                        marginRight: "30px",
                        cursor: "pointer",
                        color: "rgba(0,0,0,.5)",
                      }}
                      onClick={() => setQuery(suggestion)}
                      key={index}
                    >
                      {suggestion}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div>
          <Space wrap style={{ alignItems: "flex-start" }}>
            <Tooltip
              placement="top"
              title={
                !keyword || !query
                  ? undefined
                  : buttonName
                  ? undefined
                  : "Select a subject"
              }
            >
              <Dropdown.Button
                overlay={menu}
                overlayStyle={{ maxHeight: "80vh", overflowY: "scroll" }}
                disabled={generate || !keyword || !query}
                loading={generate}
                icon={<DownOutlined />}
                type="primary"
                htmlType="submit"
                size="large"
                shape="round"
                style={{
                  marginRight: "20px",
                  // background: "#4FA8DC",
                  borderRadius: "10px",
                  // padding: "8px",
                  border: "none",
                }}
                className="button-dropdown-generate"
                onClick={() => {
                  if (buttonName) generateText({ eventButton: buttonName });
                }}
              >
                {buttonName || "Generate"}
              </Dropdown.Button>
            </Tooltip>
          </Space>
        </div>
        {!isLandingPage && (        
        <div>
          {/* <Space wrap style={{ alignItems: "flex-start" }}> */}
            <Tooltip
              placement="top"
              title={
                !keyword || !query
                  ? undefined
                  : buttonName
                  ? undefined
                  : "Select a subject"
              }
            >
              {/* <Button
                style={{
                  marginRight: "20px",
                  background: "#4FA8DC",
                  borderRadius: "10px",
                  // padding: "8px",
                  border: "none",
                }}
                className="button-dropdown-generate"
                size="large"
                type="primary"
                shape="round"
                disabled={generate || !keyword || !query}
                loading={generate}
                onClick={generateRephrase}
              >
                {"Aggressive"}
              </Button> */}
              <div>
              <RephraseDropdownButton
                onModelClick={generateRephrase}
                buttonText="Aggressive"
                modelAName="Model A"
                modelBName="Model B"
                loading={generate}
                disabled={generate || !keyword || !query}
                buttonStyle={{
                  marginRight: "20px",
                  background: "#4FA8DC",
                  borderRadius: "10px",
                  border: "none",
                }}
                buttonClassName="button-dropdown-generate"
                buttonSize="large"
                buttonShape="round"
              />
            </div>
            </Tooltip>
          {/* </Space> */}
        </div>
        )}
        <div>
          <Tooltip
            placement="top"
            title={
              !keyword || !query
                ? undefined
                : buttonName
                ? undefined
                : "Select a subject"
            }
          >
            <Button
              style={{
                marginRight: "20px",
                background: "#4FA8DC",
                borderRadius: "10px",
                // padding: "8px",
                border: "none",
              }}
              className="button-dropdown-generate"
              size="large"
              type="primary"
              shape="round"
              disabled={generate || !keyword || !query}
              loading={gettingVariations}
              onClick={getPromptVariations}
            >
              Variations
            </Button>
          </Tooltip>
          {/* </Space> */}
        </div>
      </div>

      {/* <GenerateTextDrawer visible={true} setVisibility={() => {}} generateTextDrawerData={null} emailId={email} firstName={displayName} /> */}

      {generate && (
        <div style={{ zIndex: "10", textAlign: "center" }}>
          <Loader
            type="TailSpin"
            color="#4FA8DC"
            height={100}
            width={100}
          />{" "}
        </div>
      )}

      {!isLandingPage && !generate && (
        <GeneratedText
          generatedText={generatedText}
          setGeneratedText={setGeneratedText}
          currentCaretPosition={currentCaretPosition}
          setCurrentCaretPosition={setCurrentCaretPosition}
          references={references}
          setReferences={setReferences}
          serpSearchResponse={serpSearchResponse}
          refrencesLoading={refrencesLoading}
          generateReferences={generateReferences}
          fetchAndSetReferencesKeyword={fetchAndSetReferencesKeyword}
          fetchAndSetReferences={fetchAndSetReferences}
        />
      )}
      {!isLandingPage && !generate && (
        <Button
          style={{
            margin: "0px 56px",
            background: "#4FA8DC",
            borderRadius: "10px",
          }}
          size="large"
          type="primary"
          shape="round"
          disabled={reGenerate}
          loading={reGenerate}
          onClick={regenerateText}
        >
          Continue Generating
        </Button>
      )}
      <Content
        style={{
          // minHeight: "100vh",
          paddingTop: "10px",
          marginBottom: "10px",
        }}
      ></Content>
      {isLandingPage && <ValueAddDescriptors />}
      {isLandingPage && <FeatureList />}
      <LoginModal
        isModalOpen={showLoginPrompt}
        setIsModalOpen={setShowLoginPrompt}
        modalMessage={loginPromptReason}
      />
    </>
  )
};
