import React, { useEffect } from "react";
import "./App.css";
import {
  Layout,
  Typography,
} from "antd";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const { Header, Content, Footer } = Layout;
const { Title, Text, Paragraph } = Typography;

function ApprovalPendingPage({approvalRequestId, setApprovalRequestId}) {

  useEffect(() => {
    
  }, []);

  return (
    <div className="App2">
      <Layout className="layout">
        <Header className="content">
          <Text
            style={{
              color: "#00FFFF",
              textAlign: "center",
              display: "block",
              margin: "0 auto",
              width: "100%",
            }}
          >
            Cherish Technologies INC
          </Text>
        </Header>
        <Content
          style={{
            minHeight: "100vh",
            padding: "10px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Title>Approval Pending</Title>
          <Paragraph>
            Your account is pending approval. Your approval request id is <strong>{approvalRequestId}</strong>. 
            Please share this id with an admin to get your login approved. 
            You can start using the app by refreshing the page after your approval.
          </Paragraph>
        </Content>
      </Layout>
    </div>
  );
}

export default ApprovalPendingPage