import React, { useEffect, useRef, useState } from "react";

import { EyeOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Drawer,
  Button,
  Typography,
  Row,
  Col,
  Input,
  Space,
  Table,
  Form,
  Tag,
  Select,
  Layout,
  Dropdown, Menu, message
} from "antd";
import moment from "moment";
import userUser from "../hooks/useUser";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import withAuth from "../hooks/withAuth";
import { apiRequester, getReassignableUsers } from "../utils/apiRequester";

const defaultUsers = [
  "6653e533841aeda563dc1174",
  "6653e484f57f17cb3f0de66a",
  "6653e4fa767dacebb84fc37f",
]

const rolesLabelMap = {
  "admin": { label: "Admin", color: "volcano" },
  "team_lead": { label: "Team Lead", color: "purple" },
  "handler": { label: "Handler", color: "geekblue" },
  "user": { label: "User", color: "blue" },
}

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const AdminUsersPage = () => {

  const [addUserDrawerVisibility, setAddUserDrawerVisibility] = useState(false);
  const [inputSearchString, setInputSearchString] = useState("");
  const debouncedSearchString = useDebounce(inputSearchString, 500);
  const { notGeneralUserCheck } = useAuth();
  const { users, refreshUsers, createUser, userLoading,userInfoAll,deleteUser, reassignUser } = userUser();
  const [addUserForm] = Form.useForm();
  const [paperPagination,setPaperPagination]=useState(userInfoAll)
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [reassignableUsers, setReassignableUsers] = useState([]);
  const [isReassingLoading, setIsReassignLoading] = useState(false);
  const [reassignMenuUser, setReassignMenuUser] = useState(null);

  const handleEditClick = async (_id) => {
    try {
      // setSelectedReassignedUser(_id);
      setIsReassignLoading(true);
      setReassignableUsers([]);
      const reassignableUsers = await getReassignableUsers(_id);
      setReassignableUsers(reassignableUsers);
      setReassignMenuUser(_id);
  
      if (reassignableUsers.length === 0) {
        message.info('No reassignable users found.');
      } else {
        setDropdownVisible(true);
      }
    } catch (error) {
      console.error('Error fetching reassignable users:', error);
      message.error('Failed to fetch reassignable users.');
    } finally {
      setIsReassignLoading(false);
    }
  };

  const handleDropdownItemClick = async (userId, parentId) => {
    try {
      setIsReassignLoading(true);
      setDropdownVisible(false);
      await reassignUser(userId, parentId);
    } catch (error) {
      console.error('Error reassigning user:', error);
      message.error('Failed to reassign user.');
    } finally {
      setIsReassignLoading(false);
    }
  };

  useEffect(() => {
    function getAllUsers(){
    refreshUsers();
    notGeneralUserCheck();
    }
    getAllUsers()
  }, []);

  useEffect(()=>{
    setPaperPagination(userInfoAll)
  },[userInfoAll])

  useEffect(() => {
    refreshUsers(debouncedSearchString);
  }, [debouncedSearchString]);

  const filterSearch = (users) => {
    // if (!inputSearchString) {
      return users;
    // } else
    //   return users.filter((user) => {
    //     return (
    //       user.userId?.toLocaleLowerCase()?.includes(inputSearchString) ||
    //       user.roles?.join("")?.toLocaleLowerCase()?.includes(inputSearchString)
    //     );
    //   });
  };

  const pageChange = (pageOptions) => {
    const current = pageOptions.current || 1;
    refreshUsers("",100,paperPagination.limit * (current - 1))
    setPaperPagination(pagination => ({
        ...pagination,
        skip: pagination.limit * (current - 1),
        refresh: !pagination.refresh,
    }));
};

  console.log("User Info all is",paperPagination)

  return (
    <div className="App2">
      <Layout className="layout" style={{ padding: 30 }}>
        <Typography.Title level={2}>Users</Typography.Title>
        {/* <Typography.Paragraph>Manage users</Typography.Paragraph> */}
        <Row style={{ marginBottom: "1.5rem" }}>
          <Col span={12}>
            <Input
                            placeholder="Filter users"
                            onChange={e => setInputSearchString(e.target.value)}
                            prefix={<SearchOutlined />}
                        />
          </Col>
          {/* <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Space>
                            <Button
                                icon={<PlusOutlined />}
                                type="primary"
                                onClick={() => setAddUserDrawerVisibility(true)}
                            >
                                Add User
                            </Button>
                        </Space>
                    </Col> */}
        </Row>
        <Row>
          <Table
            pagination={{
              pageSize: paperPagination?.limit || 0,
              total: paperPagination?.total || 0,
              current: paperPagination?.skip / paperPagination?.limit + 1,
              showSizeChanger: false,
          }}
          onChange={pageChange}
            style={{ width: "100%" }}
            dataSource={filterSearch(users)}
            rowKey={(record) => record._id}
            columns={[
              {
                title: "Email Id",
                dataIndex: "emailId",
                key: "emailId",
              },
              {
                title: "Created",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (createdAt) => moment(createdAt).fromNow(),
              },
              {
                title: "Role",
                dataIndex: "roles",
                key: "roles",
                render: (roles) =>
                  // (
                    <Tag color={rolesLabelMap[roles[0]]?.color ?? "red"}>{rolesLabelMap[roles[0]]?.label ?? "Unknown"}</Tag>
                  // )
              },
              {
                key: "actions",
                width: "10%",
                align: "right",
                dataIndex: "_id",
                render: (_id, user) => {
                    console.log("Id is",_id)
                  return (
                    <>
                    {!user?.roles?.includes('admin') && !defaultUsers.includes(_id) && <Dropdown
                      visible={dropdownVisible && reassignMenuUser === _id}
                      onVisibleChange={(visible) => setDropdownVisible(visible)}
                      overlay={
                        <Menu>
                          {reassignableUsers.map((user) => (
                            <Menu.Item key={user._id} onClick={() => handleDropdownItemClick(_id, user._id)}>
                              {user.emailId}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <Button 
                        // style={{marginRight:4}}
                        size="small" 
                        icon={<EditOutlined />} 
                        secondary 
                        loading={userLoading || isReassingLoading} 
                        onClick={() => handleEditClick(_id)}>
                      </Button>
                    </Dropdown>}
                    <Link to={`/report/${String(_id)}`}>
                      <Button
                      style={{ marginLeft: 4 }}
                        size="small"
                        icon={<EyeOutlined />}
                        secondary
                        loading={userLoading}
                      />
                    </Link>
                    <Button
                        size="small"
                        style={{marginLeft:4}}
                        icon={<DeleteOutlined />}
                        secondary
                        danger
                        loading={userLoading}
                        onClick={()=>{
                          deleteUser(_id)
                        }}
                      />
                    </>
                  );
                },
              },
            ]}
          />
        </Row>
        <Drawer
          visible={addUserDrawerVisibility}
          onClose={() => setAddUserDrawerVisibility(false)}
          destroyOnClose
          title="New User"
          footer={
            <Space>
              <Button onClick={() => setAddUserDrawerVisibility(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={userLoading}
                onClick={() => addUserForm.submit()}
              >
                Continue
              </Button>
            </Space>
          }
          footerStyle={{ textAlign: "right" }}
        >
          <Form
            labelCol={{ span: 24 }}
            onFinish={createUser}
            form={addUserForm}
          >
            <Form.Item
              name="emailId"
              label="Email ID"
              rules={[{ required: true, message: "Please input email!" }]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: "Please input first name!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: "Please input last name!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Please input password!" }]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: "Please select a role!" }]}
            >
              <Select>
                <Select.Option value="admin">Admin</Select.Option>
                <Select.Option value="student">Student</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Drawer>
      </Layout>
    </div>
  );
};

export default withAuth(AdminUsersPage);
