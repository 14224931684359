import React, { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DatePicker } from "antd";
import { csv } from "react-csv"; // theme css file
import { EyeOutlined } from "@ant-design/icons";

import "./report.css";
import { CSVDownload, CSVLink } from "react-csv";
import {
  Drawer,
  Button,
  Typography,
  Row,
  Col,
  Input,
  Space,
  Table,
  Form,
  Tag,
  Layout,
  TreeSelect,
} from "antd";
import Select from "react-select";
import moment from "moment";
import userUser from "../hooks/useUser";
import useAuth from "../hooks/useAuth";
import { useParams } from "react-router-dom";
import { apiRequester } from "../utils/apiRequester";
import withAuth from "../hooks/withAuth";
import { JsonToCsv, useJsonToCsv } from "react-json-csv";
import store from "store";

const { RangePicker } = DatePicker;

export const Report = () => {
  const { notGeneralUserCheck, loggedInUser } = useAuth();
  const params = useParams();
  const { users, refreshUsers, userLoading } = userUser();
  const [selectedUser, setSelectedUser] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [csvData, setCsvData] = useState([]);
  const [csvDataGenerating, setCsvDataGenerating] = useState(false);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [userTree, setUserTree] = useState([]);

  useEffect(() => {
    refreshUsers("",1000,0);
  }, []);

  useEffect(() => {
    setUserTree(getHierarchy());
    if (params.userId && users.length) {
      const matchingUser = users.find((usr) => usr._id === params.userId);
      if (matchingUser) {
        setSelectedUser({
          label: `${matchingUser.firstName} ${matchingUser.lastName} (${matchingUser.emailId})`,
          value: matchingUser.emailId,
        });
      }
    }
  }, [users]);

  function getHierarchy() {
    const treeData = [];
    // const localUser = store.get("user");
    // const localUserRole = localUser ? localUser.roles[0] : "user";

    // Map to store node by id
    const nodeMap = new Map();
    if (!users | !users.length) return [];
    const sortedUsersByLevel = [...users].sort((a,b)=>a.ancestors.length - b.ancestors.length);
    // Iterate over nodes
    const highestLevelRole = sortedUsersByLevel[0].roles[0];
    sortedUsersByLevel.forEach(node => {

      // Create node object
      const newNode = {
        title: `${node.firstName} ${node.lastName} (${node.emailId})`,
        value: node.emailId  
      };

      // Add to map
      nodeMap.set(node._id, newNode);

      // If root node
      if ((highestLevelRole === 'admin' && node.roles[0] === 'admin') || (highestLevelRole !== 'admin' && node.roles[0] === highestLevelRole)) {
        treeData.push(newNode);
      } else {
        // Find parent node and add as child 
        const parent = nodeMap.get(node.ancestors[node.ancestors.length - 1]);
        parent.children = [...(parent.children || []), newNode]; 
      }

    });
    return treeData;
  }

  const filename = "Text Gen Report",
    fields = {
      dname: "Name",
      email: "Email",
      gen_text: "Generated Text",
      prompt: "Prompt",
      subject: "Subject",
      time_stamp: "Timestamp",
    },
    style = {
      padding: "5px",
    },
    text = "Convert Json to Csv";

  const fetchData = async (event, done) => {
    setCsvDataGenerating(true);
    const report = await apiRequester.downloadReport(
      selectedUser,
      startDate,
      endDate
    );
    // report.forEach(doc => doc.gen_text.replace(/\n/g, '\\\\n'))
    const transformedData = report.map((rpt) => {
      let { email, subject, prompt, gen_text, time_stamp } = rpt;
      if (Array.isArray(gen_text)) {
        if (gen_text[0] && typeof gen_text[0] === 'string' && gen_text[0].length) {
          gen_text = gen_text[0];
        } else {
          gen_text = JSON.stringify(gen_text);
        }
      }
      return {
        time_stamp,
        email,
        // subject: subject?.replace(/\n/g, '\\\\n')?.replace(/\r/g, '\\\\r')?.replace(/,/g, '\\,') ?? '',
        prompt: prompt?.replace(/\n/g, '\\\\n')?.replace(/\r/g, '\\\\r')?.replace(/,/g, '[comma]'),
        gen_text: gen_text?.replace(/\n/g, '\\\\n')?.replace(/\r/g, '\\\\r')?.replace(/,/g, '[comma]'),
      };
    });
    setCsvData(transformedData);

    // const csvData = csv(transformedData);
    // const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    // const url = URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.setAttribute('href', url);
    // link.setAttribute('download', 'report.csv');
    // link.click();

    // // Clean up the temporary URL
    // URL.revokeObjectURL(url);
    setGeneratedReport(true)
    setCsvDataGenerating(false);
    setCsvData(transformedData);
  };

  return (
    <div className="App2">
      <Layout className="layout" style={{ padding: 30 }}>
        <h1 className="text-3xl font-bold text-white">Generate Report</h1>
        <Row>
          <Col span={11}>
            <TreeSelect
              loading={userLoading}
              showSearch
              style={{ width: '100%' }}
              value={selectedUser}
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_ALL}
              // dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Roles"
              allowClear
              treeDefaultExpandAll
              onChange={(newValue) => {
                setGeneratedReport(false);
                setSelectedUser(newValue);
              }}
              treeData={userTree}
              className="basic-multi-select mt-1 w-5/6 rounded py-5"
              size="middle"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  "&:hover": {},
                  border: "1px solid #E6E7E9",
                  padding: "0.2rem",
                  width: "100%",
                }),
              }}
            />
          </Col>
          <Col span={12} style={{ marginLeft: 40 }}>
            <RangePicker
              size="large"
              style={{ width: "100%" }}
              onChange={(selectedDate) => {
                setGeneratedReport(false);
                if (selectedDate) {
                  const start = moment(selectedDate[0]).utc().startOf('day');
                  const end = moment(selectedDate[1]).utc().startOf('day');
                  setStartDate(start);
                  setEndDate(end);
                } else {
                  setStartDate(null);
                  setEndDate(null);
                }
                // setStartDate(selectedDate[0]);
                // setEndDate(selectedDate[1]);
              }}

            />
          </Col>
        </Row>
        <Button
          style={{ marginTop: 20, width: "20%" }}
          loading={csvDataGenerating}
          type="primary"
          size="large"
          onClick={fetchData}
        >
          {" "}
          Generate Report
        </Button>
        {generatedReport ? (
          <div style={{display:"flex"}}>
            Your report is ready. Click{" "}
            <CSVLink data={csvData} style={{marginLeft:4}}>
              here to download
            </CSVLink>
          </div>
        ) : null}
      </Layout>
    </div>
  );
};

export default Report;
