import axios from "axios";
import { Cookies, useCookies } from "react-cookie";
import { getCookie, getCookieOptions } from "./misc";
import jwt from "jsonwebtoken";


// const API_BASE_URL = process.env.API_URL || "https://chat-backend-h7sp.onrender.com";
// const API_BASE_URL = process.env.API_URL || 'https://chat-backend-a3v4rktvya-uc.a.run.app';
const API_BASE_URL = "https://textgen-backend.happyforest-b0203bd1.centralindia.azurecontainerapps.io";
// const API_BASE_URL = "http://localhost:4002";

let isRefreshTokenRequestInProgress = false;
let refreshTokenPromise = null;

const extractAPIError = (res) => {
  if (res?.response?.data) return res.response.data;
  else return res;
};

const extractAPIData = (res) => {
  if (res?.data) return res.data;
  else return res;
};

const cookies = new Cookies();

// Token refresh interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const errorCode = error.response?.data?.code;
    // if (errorCode === "ALREADY_LOGGED_IN" && error?.config?.url?.endsWith("/auth/login")) {

    // }

    if (errorCode === "INVALID_TOKEN" && (error?.config?.url?.endsWith("/generate") || error?.config?.url?.endsWith("/refresh"))) {
      // Clear storage and logout
      cookies.remove("authToken", getCookieOptions());
      cookies.remove("user", getCookieOptions());
      localStorage.removeItem("approvalRequest");
      localStorage.removeItem("approvalRequestId");
      localStorage.removeItem("approvalRequestTrackingId");
      localStorage.removeItem("user"); 
      //Go To Login Page
    } 

    return Promise.reject(extractAPIError(error));
  }
);

axios.interceptors.request.use(
  async (config) => {
    try {
      const token = getCookie("authToken");
      // decode token if exists using jsonwebtoken
      if (!token) {
        const nonAuthEndpoints = ["/auth/login"];
        const isNonAuthEndpoint = nonAuthEndpoints.find((endpoint) => {
          if (config.url.endsWith(endpoint)) return true;
        })
        if (isNonAuthEndpoint) return config;
        window.location.href = "/";
      }
      config.headers.Authorization = `Bearer ${token}`;

      // try {
      //   const clientIp = await axios.get("https://api.ipify.org?format=json");
      //   config.headers["X-TextGen-Client-IP"] = clientIp.data.ip;
      // } catch (error) {
      //   console.log("Error getting client IP", error);
      // }

      return config;
    }
    catch(error) {
      return Promise.reject(error);
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const refreshToken = async () => {
  const token = getCookie("authToken");
  if (!token) {
    return Promise.reject(new Error("AuthToken not found for refresh token"));
  }
  // decode token if exists using jsonwebtoken
  const decodedToken = jwt.decode(token, { complete: true });
  // check if token is expired
  if (decodedToken.payload.exp > Date.now() / 1000) {
    return token;
  }

  try {
    if (!isRefreshTokenRequestInProgress) {
      isRefreshTokenRequestInProgress = true;
      const freshTokenRes = await getFreshToken(token);
      const freshToken = freshTokenRes.token;
      cookies.set("authToken", freshToken, getCookieOptions());
      isRefreshTokenRequestInProgress = false;
    } else {
      let retryCount = 0;
      const maxRetries = 20;
      const delayMs = 1000; // 1 second delay

      while (isRefreshTokenRequestInProgress && retryCount < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, delayMs));
        retryCount++;
      }

      if (retryCount === maxRetries) {
        // Maximum retries reached, reject the request
        return Promise.reject(new Error("Maximum retries reached for refresh token"));
      }
    }
    const freshToken = getCookie("authToken");
    isRefreshTokenRequestInProgress = false;
    return freshToken;
  } catch(error) {
    isRefreshTokenRequestInProgress = false;
    return Promise.reject(error);
    // remove auth token from cookies?
  }
};

export async function getFreshToken(token) {
  try {
    if (localStorage.getItem('approvalRequest')) {
      return Promise.reject(new Error("Approval Request already exists"));
    }
    let additionalMetadata = {};
    try {
      const ipinfo = await (await fetch('https://api.ipify.org?format=json')).json();
      if (ipinfo?.ip) {
        additionalMetadata.ipAddress = ipinfo.ip;
      }
      additionalMetadata.userAgent = navigator?.userAgent;
      const browserId = localStorage.getItem("browserId");
      if (browserId) {
        additionalMetadata.browserId = browserId;
      }
    } catch (error) {
      // ipinfo = null;
    }
    const res = await axios.post(`${API_BASE_URL}/auth/refresh`, { token, additionalMetadata }, {
      headers: { "content-type": "application/json" },
    });
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
}

// Login
export const login = async (params) => {
  try {
    try {
      const ipinfo = await (await fetch('https://api.ipify.org?format=json')).json();
      if (ipinfo?.ip) {
        params.ip = ipinfo.ip;
      }
      params.userAgent = navigator?.userAgent;
      const browserId = localStorage.getItem("browserId");
      if (browserId) {
        params.browserId = browserId;
      }
    } catch (error) {
      // ipinfo = null;
    }
    const res = await axios.post(`${API_BASE_URL}/auth/login`, params, {
      headers: { "content-type": "application/json" },
    });
    return extractAPIData(res);
  } catch (error) {
    if (error?.code === "ALREADY_LOGGED_IN") {
      const approvalRequestRes = error?.extra?.approvalRequest
      const approvalRequestId = error?.extra?.approvalRequest?.insertResult?.insertedId;
      const approvalRequestTrackingId = error?.extra?.approvalRequest?.trackingId
      const authToken = error?.extra?.approvalRequest?.token;
      cookies.set("authToken", authToken, getCookieOptions());
      localStorage.setItem("approvalRequest", JSON.stringify(approvalRequestRes));
      localStorage.setItem("approvalRequestId", approvalRequestId);
      localStorage.setItem("approvalRequestTrackingId", approvalRequestTrackingId);
      localStorage.removeItem("user"); 
      // reroute to /approval
    }
    return Promise.reject(extractAPIError(error));
  }
};

export const createUser = async (user) => {
  try {
    await refreshToken();
    const res = await axios.post(`${API_BASE_URL}/users`, user, {
      headers: { "content-type": "application/json" },
    });
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};
export const downloadReport = async (email, startDate, endDate) => {
  try {
    await refreshToken();
    const res = await axios.post(
      `${API_BASE_URL}/users/report`,
      {
      email,
      startDate,
      endDate,
      },
      {
        headers: { "content-type": "application/json" },
      }
    );
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const getLastWeekGenerations = async ({email}) => {
  try {
    await refreshToken();
    const endDate = new Date().toISOString();
    const startDate = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString();

    const res = await axios.post(
      `${API_BASE_URL}/users/report`,
      {
      email : [email],
      startDate,
      endDate,
      },
      {
        headers: { "content-type": "application/json" },
      }
    );
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const getPastLoginFingerprintDetails = async ({email}) => {
  try {
    await refreshToken();
    const res = await axios.post(
      `${API_BASE_URL}/users/fingerprints/report`,
      {
        email,
      },
      {
        headers: { "content-type": "application/json" },
      }
    );
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const getPastLoginApprovalRequestDetails = async ({email}) => {
  try {
    await refreshToken();
    const res = await axios.post(
      `${API_BASE_URL}/users/loginrequest/report`,
      {
        email,
      },
      {
        headers: { "content-type": "application/json" },
      }
    );
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const getUsersPastLoginRequests = async (email) => {
  try {
    await refreshToken();
    const endDate = new Date().toISOString();
    const startDate = new Date(endDate.getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString();

    const res = await axios.post(
      `${API_BASE_URL}/users/report`,
      {
      email,
      startDate,
      endDate,
      },
      {
        headers: { "content-type": "application/json" },
      }
    );
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const getUser = async (id) => {
  try {
    await refreshToken();
    const res = await axios.get(`${API_BASE_URL}/users/${id}`, {
      headers: { "content-type": "application/json" },
    });
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const updateUser = async (id, user) => {
  try {
    await refreshToken();
    const res = await axios.put(`${API_BASE_URL}/users/${id}`, user, {
      headers: { "content-type": "application/json" },
    });
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};
export const getMessagesForConversation = async (conversationId) => {
  try {
    await refreshToken();
    const res = await axios.get(`${API_BASE_URL}/message/${conversationId}`, {
      headers: { "content-type": "application/json" },
    });
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const listUsers = async (text = "",limit=100,skip=0) => {
  try {
    await refreshToken();
    const res = await axios.get(`${API_BASE_URL}/users?limit=${limit}&skip=${skip}${text ? `&text=${text}` : ''}`, {
      headers: { "content-type": "application/json" },
    });
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const deleteUser = async (_id) => {
  try {
    await refreshToken();
    const res = await axios.delete(`${API_BASE_URL}/users/${_id}`, {
      headers: { "content-type": "application/json" },
    });
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const reassignUser = async (_id, parentId) => {
  try {
    await refreshToken();
    const res = await axios.put(`${API_BASE_URL}/users/${_id}/reassign`, {
      parentId, userId: _id
    });
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const getReassignableUsers = async (userId) => {
  try {
    await refreshToken();
    const res = await axios.get(`${API_BASE_URL}/users/${userId}/reassign`, {
      headers: { "content-type": "application/json" },
      userId: userId
    });
    return extractAPIData(res);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const generateTextProxy = async (params, customUrl) => {
  try {
    await refreshToken();
    const {text, buttonName, email, displayName} = params;
    const url = customUrl || `https://textgen-api.happyforest-b0203bd1.centralindia.azurecontainerapps.io/generate`;
    // const url = `http://localhost:8080/generate`;

    const form = new FormData();
    form.set("text", text);
    form.set("buttonName", buttonName);
    form.set("email", email);
    form.set("displayName", displayName);

    const generateRes = await axios.post(url, form, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
    return extractAPIData(generateRes);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const getApprovalRequestStatus = async (params) => {
  try {
    await refreshToken();
    const { requestId } = params;
    const generateRes = await axios.post(`${API_BASE_URL}/auth/approval/request`, {
      requestId
    });
    return extractAPIData(generateRes);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const approveApprovalRequest = async (params) => {
  try {
    await refreshToken();
    const { requestId } = params;
    const generateRes = await axios.post(`${API_BASE_URL}/auth/approval/approve`, {
      requestId
    });
    return extractAPIData(generateRes);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const addFingerprintInfoToLoginApprovalRequests = async (params) => {
  try {
    await refreshToken();
    const { token, approvalRequestId, fingerprintRequestId, visitorDetails } = params;
    const generateRes = await axios.post(`${API_BASE_URL}/auth/approval/request/fingerprint/add`, {
      approvalRequestId,
      fingerprintRequestId,
      visitorDetails,
      token,
    });
    return extractAPIData(generateRes);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const addFingerprintInfoForSuccessfulLogin = async (params) => {
  try {
    await refreshToken();
    const { token, user, fingerprintRequestId, visitorDetails } = params;
    const generateRes = await axios.post(`${API_BASE_URL}/auth/fingerprint/add`, {
      user,
      fingerprintRequestId,
      visitorDetails,
      token,
    });
    return extractAPIData(generateRes);
  } catch (error) {
    return Promise.reject(extractAPIError(error));
  }
};

export const apiRequester = {
  login,
  createUser,
  listUsers,
  deleteUser,
  updateUser,
  getUser,
  downloadReport,
  getFreshToken,
  reassignUser,
};
