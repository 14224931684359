import React from "react"
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import store from "store";


const withAuth =(Component) => {
  const AuthenticatedComponent = (props) => {
    const router = useNavigate();
    const [cookies]=useCookies(["authToken","user"])
    const location = useLocation();
    
    useEffect(() => {
      // if (cookies["authToken"] && store.get("approvalRequest")) {
      //   router('/approval');
      // }
      console.log(router.pathname)
      console.log(location.pathname)
      if (!cookies["authToken"]) {
        router('/');
      }
      else if (router.pathname === '/login') {
        router('/');
      }
      else if (location.pathname === '/admin'){
        if(store.get("user")?.roles?.includes("user"))
          router('/')
      }
      else if (location.pathname.includes('/approvals/')){
        if(!store.get("user")?.roles?.includes("admin"))
          router('/')
      }
    }, []);

    return <Component {...props} />;
  };

  return AuthenticatedComponent;
};

export default withAuth;