import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import { App as NewApp } from './AppRFC';
import * as serviceWorker from './serviceWorker';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { RephrasePage } from './RephrasePage';
import ErrorPage from "./error-page";
import { Parent } from './Parent';
import Admin from './screens/Admin';
import AdminUsersPage from './screens/Users';
import Report from './screens/Report';
import LoginApprovalActionPage from './LoginApprovalActionPage';
import BrowserCheck from './components/BrowserCheck';
// import {
//   FpjsProvider,
//   // defaultEndpoint,
//   // defaultScriptUrlPattern
// } from '@fingerprintjs/fingerprintjs-pro-react'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Parent />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/rephrase",
        element: <RephrasePage />,
      },
      {
        path: "/users",
        element: <AdminUsersPage />,
      },
      {
        path: "/approvals/:approvalId",
        element: <LoginApprovalActionPage />,
      },
      {
        path: "/approvals/",
        element: <LoginApprovalActionPage />,
      },
      {
        path:"/admin",
        element:<Admin/>,
      },
      {
        path:"/report",
        element:<Report/>,
        exact:true
      },
      {
        path:"/report/:userId",
        element:<Report/>
      },
      {
        path: "/",
        element: <NewApp />,
      },
    ],
  }
]);

ReactDOM.render(
  <React.StrictMode>
    {/* <FpjsProvider
      loadOptions={{
        apiKey: "T4cOv7EB8Wie2imurHYc",
        zeroTrust: true,
        // endpoint: ["<CUSTOM_ENDPOINT>", defaultEndpoint],
        // scriptUrlPattern: ["<CUSTOM_SCRIPT_URL>", defaultScriptUrlPattern],
        // region: "eu"
      }}
    > */}
    <BrowserCheck>
      <RouterProvider router={router} />
    </BrowserCheck>
    {/* </FpjsProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
