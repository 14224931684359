import React, { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';

const errorStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  fontFamily: 'Arial, sans-serif',
  backgroundColor: '#f0f0f0',
  color: '#333',
};

const messageStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '20px',
  textAlign: 'center',
};

const iconStyle = {
  fontSize: '64px',
  marginBottom: '20px',
};

function BrowserCheck({ children }) {
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    const parser = new UAParser();
    const browser = parser.getBrowser();
    setIsChrome(browser.name === 'Chrome');
  }, []);

  if (!isChrome) {
    return (
      <div style={errorStyle}>
        <div style={iconStyle}>🚫</div>
        <div style={messageStyle}>
          Sorry, this app is only supported on Chrome browsers.
        </div>
        <div>
          Please open this application in Google Chrome to continue.
        </div>
      </div>
    );
  }

  return <>{children}</>;
}

export default BrowserCheck;