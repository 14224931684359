import React, { Component } from "react";
import { Dropdown, Menu } from "antd";
import { HistoryOutlined } from "@ant-design/icons";

export const VariationHistoryMenu = ({ variationHistory, setQuery }) => {
  const handleMenuItemClick = (event) => {
    setQuery(event.key);
  };

  const menu = (
    <Menu>
      {variationHistory.length === 0 ? (
        <Menu.Item disabled>No history to show.</Menu.Item>
      ) : (
        variationHistory
        .filter(obj => obj !== null && typeof obj === 'object' && Object.keys(obj).length)
        .map((promptObj) => {
          const [promptKey, variations] = Object.entries(promptObj)[0];
          if (!Array.isArray(variations)) {
            return null;
          }          
          return (
            <Menu.SubMenu key={promptKey} title={promptKey}>
              {variations.map(variation => (
                <Menu.Item key={variation} onClick={handleMenuItemClick}>{variation}</Menu.Item>
              ))}
            </Menu.SubMenu>
          );
        })
      )}
    </Menu>
  );

  return (
    <Dropdown id="prompt-history" trigger={["click"]} overlay={menu}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <HistoryOutlined style={{ fontSize: "24px", color: "#4FA8DC" }} />
      </a>
    </Dropdown>
  );
};
